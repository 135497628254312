import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [2,3,4];

export const dictionary = {
		"/auth/app/nowe-haslo": [~5,[2]],
		"/auth/dolacz-do-nas": [~6,[2]],
		"/auth/login": [~7,[2]],
		"/auth/nowe-haslo": [~8,[2]],
		"/auth/nowe-konto": [9,[2]],
		"/auth/przypomnij-haslo": [10,[2]],
		"/auth/wyloguj": [~11,[2]],
		"/panel/grupy": [12,[3]],
		"/panel/home": [13,[3]],
		"/panel/kalendarz": [~14,[3]],
		"/panel/oferty": [~15,[3]],
		"/panel/oferty/dodaj-oferte": [~16,[3]],
		"/panel/oferty/moje-oferty": [~17,[3]],
		"/panel/oferty/zglos-punkt": [~18,[3]],
		"/panel/ustawienia/galeria": [19,[3,4]],
		"/panel/ustawienia/pracownicy": [20,[3,4]],
		"/panel/ustawienia/profil-firmowy": [21,[3,4]],
		"/panel/ustawienia/social-media": [22,[3,4]],
		"/panel/ustawienia/zmiana-hasla": [23,[3,4]],
		"/sentry-example": [24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';